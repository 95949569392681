// https://tower.im/teams/146563/documents/24726/?fullscreen=false

// 权限狗文档， 有问题问小泉， 我和方丈都看不懂🔪

// 可以购买视频套餐
const canBuyVideoPackage = ['摄影师', '企业客户', '双身份']

// 购买视频直播权限
const canBuyLiveVideo = ['摄影师', '企业客户', '双身份']

// 购买相册服务
const canAlbumPackage = ['摄影师', '企业客户', '双身份']

// 购买修图权限
const canBuyretouch = ['摄影师', '企业客户', '双身份']

//  ai修图权限
const canBuyAiretouch = ['摄影师', '企业客户', '双身份']

//  ai挑图权限
const canBuyAiChoose = ['摄影师', '企业客户', '双身份']

// 相册改版 是否是企业用户
const companyUser = ['企业客户']

//相册改版 是否可以看到闪传会员
const canBuyVip = ['摄影师', '企业客户', '双身份']

//相册改版 是否可以看到存储包
const canBuyStorage = ['摄影师', '企业客户', '双身份']

//相册改版 是否可以看到相册功能
const canBuyFunction = ['摄影师', '企业客户', '双身份']

//  可以查看相册会员的用户权限
const canSeeAlbumMemberAdv = ['摄影师', '企业客户']

// 身份类型 0 摄影师 1企业客户 2 双身份 3 未知身份客户） 双身份是可以在crm配置的，一般是企业用户加上摄影师
const userTypeMap = [ '摄影师', '企业客户', '双身份', '未知身份客户']



export default class UserShopAuth {
  constructor(data) {
    const userType = userTypeMap[data.customerId]
    this.canAlbumPackage = canAlbumPackage.includes(userType)
    this.canBuyVideoPackage = canBuyVideoPackage.includes(userType)
    this.canBuyLiveVideo = canBuyLiveVideo.includes(userType)
    this.canBuyretouch = canBuyretouch.includes(userType)
    this.canBuyAiretouch = canBuyAiretouch.includes(userType)
    this.canBuyAiChoose = canBuyAiChoose.includes(userType)
    this.canSeeAlbumMemberAdv = canSeeAlbumMemberAdv.includes(userType)
    this.companyUser = companyUser.includes(userType)
    this.canBuyVip = canBuyVip.includes(userType)
    this.canBuyStorage = canBuyStorage.includes(userType)
    this.canBuyFunction = canBuyFunction.includes(userType)
    this.userType = data.customerId
    this.userIdN = data.userIdN
  }
}


// // 可以购买视频套餐
// const canBuyVideoPackage = ['摄影师', '企业', '购买了配件与设备的销售客户']

// // 购买视频直播权限
// const canBuyLiveVideo = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// // 购买相册服务
// const canAlbumPackage = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// // 购买修图权限
// const canBuyretouch = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// //  ai修图权限
// const canBuyAiretouch = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// //  ai挑图权限
// const canBuyAiChoose = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// // 相册改版 是否是企业用户
// const companyUser = ['购买了配件与设备的销售客户', '销售客户']

// //相册改版 是否可以看到闪传会员
// const canBuyVip = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// //相册改版 是否可以看到存储包
// const canBuyStorage = ['摄影师', '企业', '购买了配件与设备的销售客户', '销售客户']

// //相册改版 是否可以看到相册功能
// const canBuyFunction = ['摄影师', '企业', '购买了配件与设备的销售客户']

// //  可以查看相册会员的用户权限
// const canSeeAlbumMemberAdv = ['摄影师', '企业', '定制版企业', '', '购买了配件与设备的销售客户']

// // ０是摄影师 1是企业  3定制版企业 4 购买了配件与设备的销售客户  5 销售客户 （ 产品说抛弃掉定制版企业，当企业处理 ）
// const userTypeMap = [ '摄影师', '企业', '定制版企业', '', '购买了配件与设备的销售客户', '销售客户' ]



// export default class UserShopAuth {
//   constructor(data) {
//     const userType = userTypeMap[data.userType]
//     this.canAlbumPackage = canAlbumPackage.includes(userType)
//     this.canBuyVideoPackage = canBuyVideoPackage.includes(userType)
//     this.canBuyLiveVideo = canBuyLiveVideo.includes(userType)
//     this.canBuyretouch = canBuyretouch.includes(userType)
//     this.canBuyAiretouch = canBuyAiretouch.includes(userType)
//     this.canBuyAiChoose = canBuyAiChoose.includes(userType)
//     this.canSeeAlbumMemberAdv = canSeeAlbumMemberAdv.includes(userType)
//     this.companyUser = companyUser.includes(userType)
//     this.canBuyVip = canBuyVip.includes(userType)
//     this.canBuyStorage = canBuyStorage.includes(userType)
//     this.canBuyFunction = canBuyFunction.includes(userType)
//     this.userType = data.userType
//   }
// }
