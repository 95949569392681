/*
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-06-10 11:40:09
 * @LastEditors: Nxl3477
 * @LastEditTime: 2021-04-20 14:35:19
 */
import md5 from '../module/md5';
import { getCookie } from '../module/cookie'
import { versionMajorMinor } from 'typescript';
import {gsUrl, albumRevisionUrl} from '../config/env'
import store from '../store/store'

// 选择签名类型
const signature = (options,version = 'v1',from,newtoken) => {
	if(version == 'v1'){
        return v1(options);
    }
    if(version == 'v3'){
        return v3(options,from,newtoken);
    }
    if(version == 'v4'){
        return v4(options,from,newtoken);
    }
}

// 获取token
const getToken = () => {
    return (getCookie('cookieUserInfoV1') && JSON.parse(getCookie('cookieUserInfoV1')).token) || 'null';
}

const getGsGrayInfo = () => {
  return store.state.gsGrayInfo || null
}

// rest、rest/v1、rest/v2接口签名算法
const v1 = options => {
    let signatureStr = '',
		signatureMd5 = '',
        token = getToken(),
        timestamp = new Date().getTime(),
        param = options;

    let objKey = Object.keys(param).sort();
    for (let i = 0, len = objKey.length; i < len; i++)
        signatureStr += '/' + param[objKey[i]];

    signatureStr += `/${timestamp}/${token}`;
    // console.log(token)
    signatureMd5 = md5(signatureStr);

    return {
        'signature': `${timestamp}/${token}/${signatureMd5}`,
        'obj': {
            'timestamp': timestamp,
            'token': token,
            'signatrue': signatureMd5
        }
    };
}

// rest/v3接口签名算法
const v3 = (options,from,newtoken) => {
    let signatureStr = '',
        signatureMd5 = '',
        param = {
            from: from,
            version: '0',
            token: getToken(),
            // token: '2829bab9142490f2e68647d9f4243a61',
            timestamp: new Date().getTime()
        };
        if (newtoken) param.token = newtoken
    Object.assign(param,options);
    let objKey = Object.keys(param).sort();
    let sortArr = [];
    for (let i = 0, len = objKey.length; i < len; i++)
        signatureStr += '/' + param[objKey[i]];
    signatureMd5 = md5(signatureStr);
    // console.log(window.guid)
    // console.log(signatureStr)
    if (!newtoken) {
        return {
            'signature': `v${param.from}-${param.timestamp}-${param.token}-${param.version}-${signatureMd5}-${md5(window.guid)}`,
            'obj': param
        };
    } else {
        return {
            'signature': `v${param.from}-${param.timestamp}-${newtoken}-${param.version}-${signatureMd5}-${md5(window.guid)}`,
            'obj': param
        }
    }
}

const v4 = (options,from,newtoken) => {
    let signatureStr = '',
        signatureMd5 = '',
        param = {
            from: from,
            version: '0',
            token: getToken(),
            timestamp: new Date().getTime()
        };
        if (newtoken) param.token = newtoken
    Object.assign(param,options);
    let objKey = Object.keys(param).sort();
    // console.log('objKey', objKey)
    let sortArr = [];
    for (let i = 0, len = objKey.length; i < len; i++)
        signatureStr += '/' + param[objKey[i]];
        // console.log('signatureMd5',signatureStr)
    signatureMd5 = md5(signatureStr);
    // console.log(signatureStr)
    if (!newtoken) {
        return {
            'signature': `v${param.from}-${param.timestamp}-${param.token}-${param.version}-${signatureMd5}`,
            'obj': param
        };
    } else {
        return {
            'signature': `v${param.from}-${param.timestamp}-${newtoken}-${param.version}-${signatureMd5}`,
            'obj': param
        }
    }
}


// 请求路径拼接
export default (url, options={},sign,from = 100101, newtoken) => {
  let newUrl = url
  // 总开关
  if (newUrl.includes(gsUrl)) {
    const gsGrayInfo = getGsGrayInfo()
    if(gsGrayInfo && (!gsGrayInfo.useGs || !gsGrayInfo.isGrayGs)) {
      newUrl = newUrl.replace(gsUrl, albumRevisionUrl)
    }
  }
    let signatureType = 'v1';

    if(newUrl.indexOf('rest/v3') >= 0 || newUrl.indexOf('http://biu.') >= 0 || newUrl.indexOf('rest/v4') >= 0){
        signatureType = 'v3';
    }

    if(newUrl.indexOf('https://ct.') >= 0 || newUrl.indexOf('https://act.') >= 0) {
        signatureType = 'v4'
    }

    sign = sign || options;
    let _sign = signature(sign ,signatureType,from,newtoken);

	for (let i in options) {
		newUrl += '/' + i + options[i];
    }
    // if(newUrl.indexOf('/rest/v3/album/set/aiRetouchSetting') >= 0) console.log(_sign.signature)
	return newUrl + '/' + _sign.signature;
}
