export default {
  bind: function (el, binding, vnode) { 
    el.clickOutsideEvent = function (event) { 
      // 判断点击的区域是否在el内部 
      if (!(el == event.target || el.contains(event.target))) { 
        // 如果不在，触发指令绑定的方法 
        vnode.context[binding.expression](event);
      } 
    }; 
    document.body.addEventListener('click', el.clickOutsideEvent); 
  }, 
  unbind: function (el) { 
    document.body.removeEventListener('click', el.clickOutsideEvent); 
  }, 
}
