//重写alert
import alert from '../module/alert'
window.alert = alert;

//tip提示框
// import tipBox from '../module/tipBox'
// window.tipBox = tipBox;

//Cookie操作
import {setCookie, getCookie, removeCookie,removeAllCookie} from '../module/cookie'
window.setCookie = setCookie;
window.getCookie = getCookie;
window.removeCookie = removeCookie;
window.removeAllCookie = removeAllCookie;