/** 服务器对时
 * @author  wiki
 * @date    2019-10-9 15:43:38
 * @last    2019-10-9 15:43:38
 *
 * @param  {String}   fileName
 * @param  {Blob}     content
 */
// import axios from 'axios'
import store from '../store/store'
import getters from '../store/getter'
import mutations from '../store/mutations'
// import { fcApi } from '../../config'
// export const timing = async () => {
//   try {
//     const request = await axios({
//       method: 'get',
//       timeout: 1000,
//       url: fcApi + `/v4/timing?t=${+new Date()}`
//     })
//     const res = request.data
//     if (res.e === 0) {
//       const jetlag = (Number(res.d.server) - Number(res.d.client))
//       mutations.SAVE_JETLAG(store.state, jetlag)
//       return true
//     }
//     return false
//   } catch (e) {
//     return false
//   }
// }

export const getTime = () => {
  return new Date().getTime() + getters.getJetlag(store.state)
}
