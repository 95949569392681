import { TrackBrowser } from '@alltuu/track'
import store from "../store/store"
import { getCookie } from "./cookie"
import { v4 as uuidv4 } from 'uuid'

/**
 *  初始化基础数据 注册时间、用户id
 * @returns { userId, registerTime }
 */
function initialParams () {
  let userId = store.state.userInfo.userId
  if (!userId) {
    const uidByCookie = getCookie('cookieUserInfoV1')
    if (uidByCookie) {
      userId = JSON.parse(uidByCookie)?.userId
    }
  }
  const registerTime = window.localStorage.getItem('USER_REGISTER_TIME') || 0
  return {
    userId,
    registerTime
  }
}

export const aiTrack = new TrackBrowser({
  config: {
    host: 'cn-hangzhou.log.aliyuncs.com',
    project: process.env.VUE_APP_ENV === 'production' ? 'ai-platform-data-analysis' : 'ai-data-analysis',
    logstore: 'ai_data_buried_encryption',
    time: 1,
    count: 10
  },
  globalData: {
    _from_: 100101,
    deviceType: 1
  }
})

export const aiTrackEvent = (from, to) => {

  if (!store.state.isCheckedNewUser) {
    store.commit('SET_CHECKED_NEWUSER')
    checkNewUser()
  }

  if (!store.state.aiLinkId) {
    const linkId = window.sessionStorage.getItem('ALLTUU_AI_LINK_ID') ?? uuidv4()
    window.sessionStorage.setItem('ALLTUU_AI_LINK_ID', linkId)
    store.commit('SET_AILINKID', linkId)
  }

  if (!checkNeedTrack(from, to)) return

  let userId = store.state.userInfo.userId
  if (!userId) {
    const uidByCookie = getCookie('cookieUserInfoV1')
    if (uidByCookie) {
      userId = JSON.parse(uidByCookie).userId
    }
  }

  let toVal, fromVal
  if (typeof to === 'string') {
    toVal = to
  } else {
    toVal = to.meta.describe || '未知'
  }

  if (typeof from === 'string') {
    fromVal = from
  } else {
    fromVal = from.meta.describe || (from.fullPath === '/' ? '新tab' : from.fullPath)
  }
  // t为1表示是页面跳转 不是点击事件
  aiTrack.add({
    topic: 'PC页面访问',
    to: toVal,
    x: userId,
    from: fromVal,
    lId: store.state.aiLinkId,
    t: 1,
    newU: Number(store.state.newUserSign)
  })
}

// 注册24小时内
export function checkNewUser () {
  const registerTime = Number(window.localStorage.getItem('ALLTUU_NEWUSER_SIGN'))
  if (Date.now() - registerTime < 86400000) {
    store.commit('SET_NEWUSER_SIGN', { sign: true, setStore: false })
  } else {
    window.localStorage.removeItem('ALLTUU_NEWUSER_SIGN')
  }
}

export function checkNeedTrack (from, to) {
  if (typeof to === 'string' || typeof from === 'string') return true
  const notNeedTrack = ['newWorkbench-basicSetting', 'download', 'newRetouch', 'newWorkbench-imagesManagement', 'auditing-photoAudit']
  if (from.path === '/' && notNeedTrack.includes(to.name)) {
    return false
  }
  return true
}

export function aiTrackClickEvent (topic, clickEvent) {
  aiTrack.add({
    topic,
    lId: store.state.aiLinkId,
    x: store.state.userInfo.userId || -1,
    clickEvent,
    newU: Number(store.state.newUserSign),
    deviceType: 1
  })
}

/**
 *
 * @param {string} event 用户行为名称
 */
export function aiTrackUserUsage (event, albumId = -1) {
  const { userId, registerTime } = initialParams()
  aiTrack.add({
    topic: '用户使用情况分析',
    x: userId,
    registerTime,
    event,
    albumId
  })
}
