/*
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-08-17 12:12:58
 * @LastEditors: Do not edit
 * @LastEditTime: 2020-08-17 14:39:21
 */
import loading from './loading'
import Loading from './loadingComponent'

export default (Vue) => {
  Vue.component(Loading.name, Loading)
  Vue.prototype.$loading = loading
  window.$loading = loading
  window.$loading.install()
}
