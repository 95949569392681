/*
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-08-19 20:11:59
 * @LastEditors: Do not edit
 * @LastEditTime: 2020-08-19 20:22:01
 */
/**
 * 统计功能
 *
 * @param   {String}  packName  统计名称，必须为中文
 * @param   {Object}  data      统计参数
 *    @param  {Number}  stayTime      页面停留时间
 *    @param  {String}  url           当前页面路径
 *    @param  {String}  source        来源
 * @param   {String}  async     是否异步
 */
import qs from 'qs'
import { statisticsApi } from '../../config/env'
import signature from '../signature'

// https://tower.im/projects/90ed20aa104f4bb189c2f6377031ee3b/docs/d74227f956021a43354380c06bedfc5a/

export default (packName, data = {}, async = true) => {
  if (!packName) throw new Error('统计名称必须填写')
  if (!/^[\u4e00-\u9fa5]+$/.test(packName)) throw new Error('统计名称必须为中文')

  const _default = {
    // 相册加密id
    actIds: '',
    // 相册id
    photoId: '',
    // 具体事件
    type: '',
    // 描述/环境
    desc: '',
    // 是否冻结
    isFreeze: '',
    // 当前页面路径
    url: location.href,
    // pc是 1  app 是2  m端 是3
    source: 1
  }
  const statisticsData = Object.assign(_default, data, { type: packName })

  postData(statisticsData, async)
}

// 发送数据
async function postData (data, async) {
  ajax('post', data, !data.stayTime || async)
}

// ajax
function ajax (method = 'post', data, async = true) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    // 请求成功
    xhr.onreadystatechange = function () {
      const res = xhr.responseText ? JSON.parse(xhr.responseText) : {}
      resolve(res)
    }

    // 请求出错
    xhr.onerror = function (e) {
      reject(e)
    }
    xhr.open(method.toUpperCase(), signature(`${statisticsApi}/rest/v3/albumStatics`, {}, data), async)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send(qs.stringify(data))
  })
}
