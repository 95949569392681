import { getSeoBeeConfig } from '../service/getData'
import { nowDomain } from "@/config/env";

export default async function seoSetting(to) {
  const seoConfig = await getSeoBeeConfig()
  const { fullPath } = to
  const routerPrefix = seoConfig.find(item => fullPath.indexOf(`/${item.keywords}`) === 0) || ''
  const cityPrefix = routerPrefix && routerPrefix.value
  const titMap = {
    'guituu': '龟途网',
    'speedervision': 'speedervision',
    'piufoto': 'Piufoto云摄影',
    'alltuu': `图片直播-照片直播闪传平台-喔图云摄影${cityPrefix}`
  }

  switch (to.name) {
    // 首页
    case 'propagandist':
    case 'vToHomePage':
      setTitle(`图片直播-照片直播闪传平台-喔图云摄影${cityPrefix}`)
      setKeyword(["图片直播", "照片直播", "喔图闪传直播", "云摄影", "喔图"].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图云摄影是专业权威的图片直播、照片直播和闪传直播平台，提供公司或企业活动、会议、公关、年会等照片直播和图片闪传云摄影即时共享服务。喔图云摄影平台累计照片直播拍摄照片3亿张,图片直播服务全球300多个城市，实现了即拍即传照片直播云摄影闪传技术的全球智能影像平台。`)
      setAdapter(`https://www.${nowDomain}.com`, `https://www.${nowDomain}.com/alltuuPretty/`)
      break;

    // 照片案例
    case 'cases':
      setTitle(`照片直播-活动现场照片直播案例-喔图云摄影${cityPrefix}`)
      setKeyword(['照片直播', '活动现场照片直播', '照片直播案例', '现场照片直播'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图“照片直播”频道为你提供专业权威的照片直播、图片直播服务，同时也汇集了年会庆典、行业活动、大咖盛宴、娱乐演出、新品发布等活动现场照片直播案例，高清精致照片深受大喜爱。喔图云摄影照片直播平台累计照片直播活动应用场景全覆盖，极速出图高效曝光，活动现场照片直播已覆盖全球300+城市，累计服务50万+客户。`)
      setAdapter(`https://www.${nowDomain}.com/cases/`, `https://af.${nowDomain}.com/cases/`)
      break;

    // 视频案例
    case 'videoCases':
      setTitle(`视频直播-活动云摄影短视频直播案例-喔图云摄影${cityPrefix}`)
      setKeyword(['云摄影', '视频直播', '活动云摄影短视频直播', '云摄影案例'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图“视频直播”频道为你提供云摄影视频直播神器，海量活动现场云摄影视频直播案例，还可同步发布到抖音、快手、微博、朋友圈等引爆全场。喔图云摄影使用专业照片视频直播、绿幕拍摄、虚拟场景合成、短视频制作，全方位视觉营销，高效进行品牌推广、获客、变现，提升传播影响力业务已覆盖全球300+城市，云摄影视频直播累计服务50万+客户。`)
      setAdapter(`https://www.${nowDomain}.com/videoCases/`, `https://www.${nowDomain}.com/mVideoCases/`)
      break;
    
    // 摄影师专区
    case 'cooperationPage':
      setTitle(`摄影师喔图闪传直播专区-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传直播', '摄影师', '摄影师喔图闪传直播'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图摄影师闪传直播专区频道赋能摄影师，让摄影师独立掌握闪传直播技术，让拍摄更自由更赚钱。喔图同时和佳能、尼康技术合作，让人人都能免费做图片闪传直播，联合派瞬，开启全球影像人招募。让我们实现您对影像事业的愿景。`)
      setAdapter(`https://www.${nowDomain}.com/cooperationPage`, `https://af.${nowDomain}.com/downloadApp/`)
      break;

    // AI修图
    case 'aiIntroduce':
      setTitle(`AI智能修图-图片直播自动修图-喔图云摄影${cityPrefix}`)
      setKeyword(['AI智能修图', '智能修图', '图片直播自动修图', '自动修图'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图AI智能修图专题页为你提供智能图片直播和AI智能修图技术，该技术由喔图知图实验室自主研发AI智能修图引擎，适用于各种活动拍摄场景，AI智能修图拥有自主知识产权的视觉智能处理模型，智能图片直播后可极速出图、无损输出、智能估光、精准调色、多场景适用自动修图哦。`)
      setAdapter(`https://www.${nowDomain}.com/aiIntroduce`, `https://af.${nowDomain}.com/aiIntroduce/`)
      break;

    // AI旋转
    case 'aiSpin':
      setTitle(`AI智能旋转校正-图片直播智能校正-喔图云摄影${cityPrefix}`)
      setKeyword(['AI智能旋转校正', '图片直播智能校正', '智能旋转校正'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图AI智能旋转校正专题页为你提供智能图片直播和AI智能旋转校正技术，该技术由喔图知图实验室依托于喔图自主研发的图像感知深度学习算法与海量数据，实现图像自动旋转校正，为图像直播提供有力的技术支持。技术优势：训练数据广泛、海量图像数据、校正准确度高、对于正常拍摄照片、旋转方向准确度高达99%，适用性广，急速处理、单张照片处理速度快至0.2s。`)
      setAdapter(`https://www.${nowDomain}.com/aiSpin`, `https://af.${nowDomain}.com/aiIntroduce/`)
      break;

    //// 摄影师开放平台
    case 'photoplatform':
      setTitle(`喔图闪传直播平台-喔图云摄影${cityPrefix}`)
      setKeyword(["喔图闪传", "喔图闪传直播", "喔图闪传直播平台", "即时摄影服务"].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图云摄影作为中国人工智能小镇入驻项目,专注于即时图片直播,照片直播,活动,会议,公关等摄影即时共享服务.已累计拍摄照片1亿张,实现即拍即传云摄影闪传技术的平台化影像,驱动摄影行业向云摄影时代升级.`)
      setAdapter(`https://www.${nowDomain}.com/photoplatform`, `https://af.${nowDomain}.com/weappShop/`)
      break;

    case 'appDownload':
      setTitle(`下载App-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传直播', '摄影师', '摄影师喔图闪传直播'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图摄影师闪传直播专区频道赋能摄影师，让摄影师独立掌握闪传直播技术，让拍摄更自由更赚钱。喔图同时和佳能、尼康技术合作，让人人都能免费做图片闪传直播，联合派瞬，开启全球影像人招募。让我们实现您对影像事业的愿景。`)
      setAdapter(`https://www.${nowDomain}.com/appDownload`, `https://af.${nowDomain}.com/downloadApp/`)
      break;

    // 关于我们
    case 'aboutUs':
      setKeyword(['喔图闪传服务', '即时摄影', '即时影像', '智能影像'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图成立于2014年,作为领先的即时影像平台,注册摄影师已超过50万名,累计拍摄照片3亿张,服务全球300多个城市,实现即拍即传的全球智能影像平台.`)
      setAdapter(`https://www.${nowDomain}.com/aboutUs`)
      break;
    case 'about-agree':
      setTitle(`服务条款-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传服务', '即时摄影', '即时影像', '智能影像'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图成立于2014年,作为领先的即时影像平台,注册摄影师已超过50万名,累计拍摄照片3亿张,服务全球300多个城市,实现即拍即传的全球智能影像平台.`)
      setAdapter(`https://www.${nowDomain}.com/about/agree`)
      break;
    case 'about-feedback':
      setTitle(`意见反馈-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传服务', '即时摄影', '即时影像', '智能影像'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图成立于2014年,作为领先的即时影像平台,注册摄影师已超过50万名,累计拍摄照片3亿张,服务全球300多个城市,实现即拍即传的全球智能影像平台.`)
      setAdapter(`https://www.${nowDomain}.com/about/feedback`)
      break;
    case 'about-news':
      setTitle(`喔图资讯-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传服务', '即时摄影', '即时影像', '智能影像'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图成立于2014年,作为领先的即时影像平台,注册摄影师已超过50万名,累计拍摄照片3亿张,服务全球300多个城市,实现即拍即传的全球智能影像平台.`)
      setAdapter(`https://www.${nowDomain}.com/news/1/page1`)
      break;
    case 'about-contactUs':
      setTitle(`联系我们-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传服务', '即时摄影', '即时影像', '智能影像'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图成立于2014年,作为领先的即时影像平台,注册摄影师已超过50万名,累计拍摄照片3亿张,服务全球300多个城市,实现即拍即传的全球智能影像平台.`)
      setAdapter(`https://www.${nowDomain}.com/about/contactUs`)
      break;
    case 'about-copyright':
      setTitle(`版权说明-喔图云摄影${cityPrefix}`)
      setKeyword(['喔图闪传服务', '即时摄影', '即时影像', '智能影像'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图成立于2014年,作为领先的即时影像平台,注册摄影师已超过50万名,累计拍摄照片3亿张,服务全球300多个城市,实现即拍即传的全球智能影像平台.`)
      setAdapter(`https://www.${nowDomain}.com/about/copyright`)
      break;
    case 'specialTopic':
      setTitle(`图片直播-喔图云摄影-照片直播专题微站${cityPrefix}`)
      setKeyword(['云摄影', '图片直播', '照片直播', '喔图闪传直播', '视频直播', '杭州喔影', '喔图'].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}专题微站是喔图云摄影旗下，组合了图片直播（云摄影）、照片直播、视频直播、闪传直播、活动亮点、活动流程、直播互动等等活动板块，能像搭积木一样搭建活动主页、相册合集。`)
      setAdapter(`https://www.${nowDomain}.com/specialTopic`)
      break;
    case 'seoCasesIndex':
      setTitle(`图片直播-照片闪传直播平台-喔图云摄影${cityPrefix}`)
      setKeyword(["图片直播", "照片直播", "喔图闪传直播", "云摄影", "喔图"].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图“照片直播”频道为你提供专业权威的照片直播、图片直播服务，同时也汇集了年会庆典、行业活动、大咖盛宴、娱乐演出、新品发布等活动现场照片直播案例，高清精致照片深受大喜爱。喔图云摄影照片直播平台累计照片直播活动应用场景全覆盖，极速出图高效曝光，活动现场照片直播已覆盖全球300+城市，累计服务50万+客户。`)
      setAdapter(`https://www.${nowDomain}.com/seoCases`)
      break;
    case 'newCasesArticle':
      // 在这里不做处理
      break;
    case 'packageDownload':
      break;
    case 'packagePassword':
      break;
    case 'package':
      break;
    case 'piufotoLand':
      break;
    default:
      setTitle(to.path === '/' ? titMap[nowDomain]  : titMap['alltuu'])
      setKeyword(["图片直播", "照片直播", "喔图闪传直播", "云摄影", "喔图"].map(item => `${cityPrefix}${item}`).join(','))
      setDescription(`${cityPrefix}喔图云摄影是专业权威的图片直播、照片直播和闪传直播平台，提供公司或企业活动、会议、公关、年会等照片直播和图片闪传云摄影即时共享服务。喔图云摄影平台累计照片直播拍摄照片3亿张,图片直播服务全球300多个城市，实现了即拍即传照片直播云摄影闪传技术的全球智能影像平台。`)
      setAdapter(`https://www.${nowDomain}.com${fullPath}`)
      break;
  }
  if (['mobilePassword', 'password', 'download', 'newMobilePassword', 'packageTips'].includes(to.name)) {
    setTitle(`打包下载`)
  }
  if (['photoplatformResume', 'retouchPlatformResume', 'videoplatformResume', 'shortVideoPlatformResume', 'liveVideoPlatformResume'].includes(to.name)) {
    setTitle(`喔图云摄影在线简历-喔图云摄影${cityPrefix}`)
  }
}

/**
 * 设置页面标题
 * @param {string} title 页面标题
 */
function setTitle(title) {
  document.title = title
}

/**
 * 设置页面关键词
 * @param {string} keywords 页面关键词
 */
function setKeyword(keywords) {
  document.querySelector("meta[name='keywords']").content = keywords
}

/**
 * 设置页面描述
 * @param {string} description 页面描述
 */
function setDescription(description) {
  document.querySelector("meta[name='description']").content = description
}

/**
 * 设置适配链接
 * @param {string} desktop 桌面端适配链接 
 * @param {string} mobile 移动端端适配链接 
 */
function setAdapter(desktop, mobile) {
  // 桌面端适配链接
  if (desktop) {
    let $canonical = document.querySelector("link[rel='canonical']")
    if ($canonical) {
      $canonical.href = desktop
    } else {
      $canonical = document.createElement('link')
      $canonical.rel = 'canonical'
      $canonical.href = desktop
      document.head.appendChild($canonical)
    }
  } else {
    let $canonical = document.querySelector("link[rel='canonical']")
    if ($canonical) {
      $canonical.remove()
    }
  }

  // 桌面端适配链接
  if (mobile) {
    let $alternate = document.querySelector("link[rel='alternate']")
    if ($alternate) {
      $alternate.href = mobile
    } else {
      $alternate = document.createElement('link')
      $alternate.rel = 'alternate'
      $alternate.media = 'only screen and (max-width: 640px)'
      $alternate.href = mobile
      document.head.appendChild($alternate)
    }
  } else {
    let $alternate = document.querySelector("link[rel='alternate']")
    if ($alternate) {
      $alternate.remove()
    }
  }
}
