/*
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-08-17 12:16:08
 * @LastEditors: Do not edit
 * @LastEditTime: 2020-08-18 17:26:55
 */
import Vue from 'vue'
import loadingComponents from './loadingComponent'

const Loading = {}

// 避免重复install
Loading.installed = false
const LoadingTip = Vue.extend(loadingComponents)

Loading.install = function () {
  if (Loading.installed) return
  Vue.prototype.$loading = {}
  Vue.prototype.$loading.show = (content, type, times) => {
    // 如果页面只能有一个loading
    if (document.querySelector('.loading')) return
    const tpl = new LoadingTip({
      data: {
        content: content,
        icon: type || 'null',
        rotate: type ? '' : true
      }
    })
    document.body.appendChild(tpl.$mount().$el)
    Loading.installed = true
    if (times > 0) {
      setTimeout(() => {
        const tpl = document.querySelector('.loading')
        tpl && document.body.removeChild(tpl)
      }, times)
    }
  }
  // 隐藏loading
  Vue.prototype.$loading.hide = () => {
    const tpl = document.querySelector('.loading')
    tpl && document.body.removeChild(tpl)
  }
}

export default Loading
