import copy from './copy'
import closeOnClickOutSide from "./closeOnClickOutside"

const directives = {
  copy,
  closeOnClickOutSide
}

export default {
  install: (Vue) => {
    Object.keys(directives).forEach(item => {
      Vue.directive(item, directives[item])
    })
  }
}