<template>
  <div id="app">
    <transition name="fade">
      <router-view ref="albuml" ></router-view>
    </transition>
    <rightsidenav :showShare="false" @openGuide="openGuide"/>
  </div>
</template>

<script>
import rightsidenav from './components/rightsidenav'
export default {
  name: 'app',
  components: {
    rightsidenav
  },
  data() {
    return {
    }
  },
  mounted(){
    let env = process.env.VUE_APP_ENV
    const script = document.createElement("script")
    if (env === 'production') {
      script.src = "https://s9.cnzz.com/z_stat.php?id=1279243092&web_id=1279243092"
    } else {
      script.src = "https://v1.cnzz.com/z_stat.php?id=1279236427&web_id=1279236427"
    }
    script.language = "JavaScript"
    document.body.appendChild(script)
  },
  methods: {
    openGuide(){
      // this.$refs.albuml.openGuide()
    },
    openPop(){
      // 首页先不加放着 @openPop="openPop"
      const url = 'https://www.alltuu.com/chat.html'; //转向网页的地址;
      const name = ''; //网页名称，可为空;
      const iWidth = 800; //弹出窗口的宽度;
      const iHeight = 600; //弹出窗口的高度;
      const iTop = (window.screen.availHeight - 30 - iHeight) / 2; //获得窗口的垂直位置;
      const iLeft = (window.screen.availWidth - 10 - iWidth) / 2; //获得窗口的水平位置;
      window.open( url, name, 'height=' + iHeight + ',innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no', );
    },
  },
}
</script>

<style lang='less'>
@import './style/common.less';
#app{
  font-size: 0;
}
.generate-delay(10);

.generate-delay(@n, @i: 1) when (@i =< @n) {
  .ani-delay-@{i} {
    animation-delay: (@i * 0.5s);
  }
  .generate-delay(@n, (@i + 1));
}
.el-loading-spinner {
  font-size: 50px;

  i {
    color: #fff !important;
  }
  .path {
    stroke: #fff !important;
  }
  .el-loading-text {
    color: #fff !important;
  }
}
.customerPop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 600px;
  height: 600px;
  background: #fff;
  border-radius: 6px;
  transition: all .3s;
  z-index: 999;
  &-content{
    position: absolute;
    top: 30px;
  }
  &-close{
    position: absolute;
    top: 0;
    right: 0px;
    cursor: pointer;
  }
}
</style>
