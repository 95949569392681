/** uuid
 * @author  wiki
 * @date    2020-12-4 20:40:54
 * @last    2020-12-4 20:40:54
 */

export default () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}
