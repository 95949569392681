import axios from '../module/axios'
import { apiUrl, retouchApi, domain } from '../config/env'

// 图片查询人脸列表
// export const v4cFfl = (a, p, t) => axios(apiUrl + '/rest/v4c/ffl', { a, p, t })

// 提交问题
// export const askQuestions = (albumIdN, question) => axios(apiUrl + '/rest/v3/live/add/question', { albumIdN, question }, 'post')


export const getIndexNewsAxios = () => axios(apiUrl + `/rest/v3/index/acquire/news`, {}, 'get')

// pc 主页获取图片分类 及 合作伙伴数据
export const getImgDataAxios = () => axios(apiUrl + `/rest/v3/index/photos`, {}, 'get');

//pc获取对应域名的页面信息
export const getUserPageInfoAxios = (sub) => axios(apiUrl + `/rest/v3/album/pc/detail`, {sub}, 'get');

// 获取新pc首页的banner案例
export const getNewIndexBannerCasesAxios = () => axios(apiUrl + `/rest/v3/index/banner/list`, {}, 'get');

// 获取pc视频案例
export const getVideoCasesInfoAxios = (type, videoId, cnt, sort, videoType) => axios(apiUrl + `/rest/v3/index/video/case`, { type, videoId, cnt, sort, videoType },'get');

// pc主页获取视频案例
export const getIndexVideoCasesAxios = (cnt) => axios(apiUrl + `/rest/v3/index/video/case/change`, {cnt}, 'get')


// pc 短信验证接口 使用ip限流
export const postSmsVerAxios = ({ phone, vcodeType = 13, globalCode = "" }) => axios(apiUrl + '/rest/v3/pc/ip/limit/send/sms', { phone, vcodeType, globalCode }, 'post');


// pc 首页提交合作申请
export const postCooperationAxios = ({ contact="", phone="", actId = "", content="", code = "", address="", backFrom }) => axios(apiUrl + '/rest/v3/flashpass/nomsg/coopapply/change', { contact, phone, actId, content, code, address, backFrom }, 'post');

