
const LOCAL_REMOTE_NAME = [
  // 'settingPcBootstrap'
]

window.$getMfAssetsPath = function (mfName) {
  if (LOCAL_REMOTE_NAME.includes(mfName) && process.env.VUE_APP_ENV === 'locahost') {
    return 'http://localhost:8090'
  }

  return process.env.VUE_APP_ACM_ASSETS_PATH
}

window.$mfCache = function () {
  const url = new URL(window.location)
  // Retrieve params via url.search, passed into ctor
  const params = new URLSearchParams(url.search)
  let isNoMfCache = params.get('mfCache') === 'no' || params.get('mfCache') === 'NO' || params.get('mfCache') === '0'

  if (process.env.VUE_APP_ENV !== 'production') {
    isNoMfCache = true
  }

  return isNoMfCache ? `?t=${+new Date()}` : ''
}