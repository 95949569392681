import Vue from 'vue'
import workbenchAlert from './workbenchAlert.vue'

const alertBoxConstructor = Vue.extend(workbenchAlert)
const instance = new alertBoxConstructor()

window.$closeShowAlert = () => {
    const box = document.querySelector('#workbenchAlert')
    if( box ) {
        document.body.removeChild(box)
    }
}

export default function showAlert (msg='', clickCallBack, delay=3000) {
    let box = document.querySelector('#workbenchAlert')
    if (!box) {
        instance.vm = instance.$mount()
    }

    instance.vm.msg = msg
    instance.vm.clickCallBack = clickCallBack
    if(!box){
        document.body.appendChild(instance.vm.$el)
    }
    
}