<template>
  <div class="videoCases-videoPlayerAlert" @click.self="$emit('close')" >
    <div class="videoCases-videoPlayerAlert-container" :style="{ maxWidth: item.width }">
      <div class="videoCases-videoPlayerAlert-container-layer">
        <p class="videoCases-videoPlayerAlert-container-layer-name">{{item.videoName}}</p>
        <p class="videoCases-videoPlayerAlert-container-layer-time">视频时长 0:57</p>
        <div class="videoCases-videoPlayerAlert-container-layer-close" @click.stop="$emit('close')"><i class="iconfont">&#xe708;</i></div>
      </div>
      <video class="videoCases-videoPlayerAlert-container-video" controls autoplay :src="item.videoUrl"></video>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="less" scoped>
.videoCases-videoPlayerAlert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0 , 0, 0, 0.8);

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0 , 0, 0, 0.8);
    border-radius: 8px;
    overflow: hidden;

    &-layer {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 1;
      padding: 15px 60px 20px 20px;
      display: none;
      background:linear-gradient(180deg,rgba(0,0,0,0.34) 0%,rgba(0,0,0,0) 100%);
      box-sizing: border-box;

      &-name {
        font-size: 16px;
        color: #fff;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }

      &-time {
        margin-top: 10px;
        font-size: 12px;
        color: #fff;
      }

       &-close {
        position: absolute;
        z-index: 1;
        top: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.3);
        cursor: pointer;
        font-size: 16px;
        i{
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-51%,-50%);
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &-video {
      width: 100%;
      // object-position: top;
      height: 100%;
    }

    &:hover {
      .videoCases-videoPlayerAlert-container-layer {
        display: block;
      }
    }

  }
}
</style>
