import Vue from 'vue'
import hint from './hintBox'

const HintConstructor = Vue.extend(hint)

let oldInstance = null
let seed = 1

// 正常弹窗
export const notify = (options, callback = () => {}) => {
  // 内容
  let content = ''
  // 自动关闭时间
  let autoClose = ''
  if (typeof options === 'string') {
    // 传入string
    content = options
  } else {
    // 传入对象
    content = options.content
    autoClose = options.autoClose
  }
  // 创建实例
  const instance = new HintConstructor()
  // 文本内容
  instance.content = content
  // type
  instance.type = options.type || ''
  // 自动关闭时间
  instance.autoClose = typeof autoClose === 'number' ? autoClose : 1000

  // dom id
  const id = `message_${seed++}`
  instance.id = id

  instance.vm = instance.$mount()
  // 组件实例加入body
  document.body.appendChild(instance.vm.$el)
  // 将visible变量可见
  instance.vm.visible = true

  // 监听 离开动画结束 事件
  instance.vm.$on('click', () => {
    // 执行回调
    callback()
  })

  // 监听 离开动画结束 事件
  instance.vm.$on('closed', () => {
  })
  // 触发关闭事件
  instance.$on('close', () => {
    // 将visible 设为不可见
    instance.vm.visible = false
    setTimeout(() => {
      // dom中移除该实例
      document.body.removeChild(instance.vm.$el)
      // 卸载组件实例
      instance.vm.$destroy()
    }, 500)
  })
  oldInstance && oldInstance.vm.$destroy()
  // 组件数组中添加该实例
  oldInstance = instance

  return instance.vm
}

// 成功弹窗
export const success = (options, callback = () => {}) => {
  // 更改option
  if (typeof options === 'string') {
    options = { content: options }
  }
  options.type = 'success'
  // 触发一个notify
  return notify(options, callback)
}

// 错误弹窗
export const error = (options, callback = () => {}) => {
  if (typeof options === 'string') {
    options = { content: options }
  }
  options.type = 'error'
  return notify(options, callback)
}
