
import { getStore, removeStore } from '../module/store'
// 获取状态
function getState (state, name) {
    // 如果在全局状态中有数据直接返回
    let stroeState = state[name]
    if (!window.isNull(stroeState) && JSON.stringify(stroeState) !== '{}') {
      return stroeState
    }
  
    // 如果在全局状态中没有数据则去本地缓存中取数据
    stroeState = getStore(name)
    if (!window.isNull(stroeState)) {
      try {
        stroeState = JSON.parse(stroeState)
      } catch (e) {}
      state[name] = stroeState
      return stroeState
    }
  
    // 如果本地缓存中没有数据返回undefined
    return undefined
  }
  


export default {
    getUserInfo(state) {
        let userInfo = state.userInfo;
        return userInfo;
    },
    getWebsocketMessage (state) {
        return state.websocketMessage
    },
    // 获取资源加载状态
    getResourcesState (state) {
      return state.resourcesState
    },
    // 获取时差
    getJetlag (state) {
      const jetlag = getState(state, 'jetlag')
      if (jetlag) {
        return Number(jetlag) || 0
      } else {
        return 0
      }
    },
    getNoviceTaskList (state) {
      return state.noviceTaskList
    },
    getStorageInfo (state) {
      return state.storageInfo
    },
    getIsNetDisk (state) {
      return state.isNetDiskDeliver
    },
    getGsGrayInfo (state) {
      return state.gsGrayInfo
    }
}


