import fetch from 'node-fetch';
import {nowDomain} from '../config/env'
import { globalVisitDomainMap } from '../config/env'
//不提示的错误码
const NOT_PROMPT_ERROR_CODE = [0, 1206, 1350, 1519, 1701, 2705, 2805, 3605, 1200, 9001, 200701, 200008, 505001, 505002, 2003007, 3810, 3809, 3812, 3814, 200507, 3815, 200906, 508001, 3819, 2003080, 3823, 505008];

export default (url, data, type, method) => {
    let _request = request(url, data, type, method);
    _request.then((res) => {
        // 判断接收的错误码是e还是error
        const errorFlag = res.e || res.errorCode
        const messageInfo = res.m || res.msg
        //需要重新登录
        if (res && typeof errorFlag === 'number' && NOT_PROMPT_ERROR_CODE.indexOf(errorFlag) < 0) {
            if ([2, 5, 1008, 200001].includes(errorFlag)) {
                errorFlag == 2 && window.$tipMes('请登录后操作');
                errorFlag == 5 && window.$tipMes('登录超时，请重新登录');
                errorFlag == 1008 && window.$tipMes('登录信息已过期，请重新登录');
                errorFlag == 200001 && window.$tipMes('登录信息已过期，请重新登录');
                window.$track.add({
                    topic: '重新登录',
                    _level_: 'INFO',
                    code: errorFlag, //错误码
                    requestURL: url, //  请求接口
                    requestParams: data, // 请求参数
                    requestData: JSON.stringify(res), // 返回数据
                })
                removeAllCookie();
                location.href = `https://www.${nowDomain}.com/login/?url=https://www.${nowDomain}.com/`;
            } else if(errorFlag == 1222){
                window.$tipMes.error('摄影师域名不存在');
                window.$track.add({
                    topic: '摄影师域名不存在',
                    _level_: 'INFO',
                    code: errorFlag, //错误码
                    requestURL: url, //  请求接口
                    requestParams: data, // 请求参数
                    requestData: JSON.stringify(res), // 返回数据
                })
                location.href = `https://www.${nowDomain}.com/`;
            } else {
                window.$tipMes.error(messageInfo || errorCodes[errorFlag] || '未知错误');
            }
        }
        if (errorFlag > 0 && ![2, 5, 1008, 200001, 1222].includes(errorFlag)) {
            window.$track.add({
                topic: '接口请求异常',
                _level_: 'ERROR',
                code: errorFlag,
                requestURL: url,
                requestParams: data,
                requestData: JSON.stringify(res),
                httpCode: res.request.httpCode // http的状态码
            })
        }
    }).catch((error) => {
        console.log('请求错误，请联系客服<br/>电话：0571-87711285')
        // tipBox('请求错误，请联系客服<br/>电话：0571-87711285');
    });
    return _request;
}

async function request(url = '', data = {}, type = 'GET', method = 'fetch') {
    // console.log(url,data,type,method)
    let timestamps = new Date().getTime();
    type = type.toUpperCase();

    //数据拼接字符串
    let dataStr = '';

    Object.keys(data).forEach(key => {
        //相册改版 内容
        if(url.indexOf('gs.guituu') >= 0 || url.indexOf('gs.alltuu') >= 0 || url.indexOf('nm.guituu') >= 0 || url.indexOf('nm.alltuu') >= 0 || url.indexOf('ct.guituu') >= 0 || url.indexOf('ct.alltuu') >= 0 || url.indexOf('anm.piufoto') >= 0 || url.indexOf('act.piufoto') >= 0 || url.indexOf('/rest/v3/album/pc/sep/edit') >= 0 || url.indexOf('gs.speedervision') >= 0) {
            dataStr += key + '=' + encodeURIComponent(data[key]) + '&';
        } else {
            dataStr += key + '=' + data[key] + '&';
        }

        //只针对照片分类保存排序接口


    })

    dataStr = dataStr ? dataStr.substr(0, dataStr.lastIndexOf('&')) : '';

    if (type == 'GET' && dataStr !== '') {
        url = url + '?' + dataStr;
    }

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    let requestConfig = {
        credentials: 'true',
        method: type,
        headers: headers,
        mode: "CORS",
    }

    if (type == 'POST') {
        requestConfig.body = dataStr;
    }

    const response = await fetch(url, requestConfig);
    const responseJson = await response.json();

    responseJson.request = {
        timestamps,
        httpCode: response?.status || null
    };
    return responseJson
}
