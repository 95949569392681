/** 获取当前元素的外联样式
 * @author  wiki
 * @date    2019-3-4 15:5:58
 * @last    2019-3-4 15:5:58
 *
 * @param  {Object}  data
 */

HTMLElement.prototype.__defineGetter__('currentStyle', function () {
  return this.ownerDocument.defaultView.getComputedStyle(this, null)
})
