/** 签名算法
 * @author  wiki
 * @date    2019-10-5 17:0:27
 * @last    2019-10-5 17:0:27
 */
import md5 from 'crypto-js/md5'
import Base64 from 'crypto-js/enc-base64'
import HmacSha1 from 'crypto-js/hmac-sha1'
import SHA from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { getTime } from './timing'
import * as config from '../../config'

// v3 | v4 签名算法
export const v3Signature = (param) => {
  let signatureStr = ''
  const keys = Object.keys(param).sort()
  for (let i = 0, len = keys.length; i < len; i++) {
    signatureStr += '/' + param[keys[i]]
  }
  return md5(signatureStr)
}

// OSS 签名算法
export const OSSSignature = (url, options) => {
  const Expires = parseInt(getTime() / 1000 + 900)
  let fileName = ''
  const keys = Object.keys(options).sort()

  for (let i = 0, len = keys.length; i < len; i++) {
    fileName += `/${keys[i]}${options[keys[i]]}`
  }
  const toSignString = `GET\n\n\n${Expires}\n/${url}${fileName}`
  const Signature = encodeURIComponent(Base64.stringify(HmacSha1(toSignString, decrypt(process.env.VUE_APP_OSS_ACCESS_KEY_SECRET))))
  let param = ''
  const params = {
    OSSAccessKeyId: decrypt(process.env.VUE_APP_OSS_ACCESS_KEY_ID),
    Expires,
    Signature
  }
  for (const KEY in params) {
    param += `${param ? '&' : '?'}${KEY}=${params[KEY]}`
  }
  return { fileName, param }
}

// V3C CDN 签名算法
export const V3CCDNSignature = (fileName, options) => {
  return CDNSignature(fileName, options, 'V3')
}

// V4C CDN 签名算法
export const V4CCDNSignature = (fileName, options) => {
  return CDNSignature(fileName, options, 'V4')
}

// CDN 签名算法
export const CDNSignature = (fileName, options, version) => {
  const timestamp = parseInt(getTime() / 1000).toString(16) // 把当前时间转成16进制
  const keys = Object.keys(options).sort()
  for (let i = 0, len = keys.length; i < len; i++) { fileName += `/${keys[i]}${options[keys[i]]}` }
  const splice = `${decrypt(process.env[`VUE_APP_${version}_CDN_PRIVATE_KEY`])}${fileName}${timestamp}`
  const md5hash = md5(splice).toString()
  return `${config[`${version}CDomainName`]}/${md5hash}/${timestamp}${fileName}`
}

// 秘钥
const key = 'S35GDtvgO5KVkmV2hfX6klYOH3xYIDqP'
// 加密算法
// function encrypt (word) {
//   return SHA.encrypt(word, key).toString()
// }
// window.encrypt = encrypt

// 解密算法
function decrypt (word) {
  return SHA.decrypt(word, key).toString(Utf8)
}
// window.decrypt = decrypt

// 函数计算签名算法
// import HmacSha256 from 'crypto-js/sha256'
// import Location from '../object/Location'
// export const FCSignature = (path, method, headers) => {
//   // 转成 location 对象
//   const url = new Location(path)
//   const contentMD5 = headers['content-md5'] || ''
//   const contentType = headers['content-type'] || ''
//   let signature = ''

//   // 提取 x-fc- 开头的请求头，并按字典序排序
//   let fcHeaders = {}
//   let CanonicalizedFCHeaders = ''
//   for (const KEY in headers) {
//     if (KEY.startsWith('x-fc-')) {
//       fcHeaders[KEY] = headers[KEY]
//     }
//   }
//   let keys = Object.keys(fcHeaders).sort()
//   for (let i = 0, len = keys.length; i < len; i++) {
//     CanonicalizedFCHeaders += `${keys[i].toLowerCase()}:${fcHeaders[keys[i]]}\n`
//   }

//   // 拼接签名字符串
//   signature = `${method.toUpperCase()}\n${contentMD5}\n${contentType}\n${headers['x-fc-Date']}\n${CanonicalizedFCHeaders}${url.pathname}`

//   // 拼接 queries 字符串
//   if (Object.keys(url.query)) {
//     let params = []
//     Object.keys(url.query).forEach((obj) => {
//       params.push(`${obj}=${url.query[obj]}`)
//     })
//     params.sort()
//     signature += '\n' + params.join('\n')
//   }

//   // 签名加密
//   let sign = base64.stringify(HmacSha256(str2buffer(signature), process.env.VUE_APP_FC_ACCESS_KEY_SECRET))
//   return 'FC ' + process.env.VUE_APP_FC_ACCESS_KEY_ID + ':' + sign
// }

// // 字符串转二进制格式
// function str2buffer (str) {
//   var buf = new ArrayBuffer(str.length * 2) // 2 bytes for each char
//   var bufView = new Uint16Array(buf)
//   for (var i = 0, strLen = str.length; i < strLen; i++) {
//     bufView[i] = str.charCodeAt(i)
//   }
//   return buf
// }
