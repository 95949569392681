import * as hint from './hint'
import showAlert from './workbenchAlert'
// import Upload from '@/src/components/upload'

export default (Vue) => {
  Vue.prototype.$hint = {
    success: hint.success,
    error: hint.error
  }

  Vue.prototype.$showAlert = showAlert

  // 浏览器全局方法
  window.$hint = {
    success: hint.success,
    error: hint.error
  }
}
