export class Task {
  constructor (data = {}) {
    const btnTypeMap = {
      0: {
        btnName: 'incomplete',
        text: '去完成'
      },
      1: {
        btnName: 'completed',
        text: '领取奖励'
      },
      2: {
        btnName: 'received',
        text: '已领取'
      }
    }
    this.missionType = data.missionType // 任务类型 0:实名认证任务 1:开启AI修图任务 2:分享相册至微信任务 3:上传照片任务 4:隐藏一张照片任务 5:下载图片直播物料手册任务
    this.missionTitle = data.missionTitle
    this.missionRewards = data.missionRewards // 奖励的描述（兼容老app）
    this.missionRewardsNew = data.missionRewardsNew // 奖励的闪传币文案：闪传币x100
    this.missionState = data.missionState // 任务状态 0:未完成 1:待领取 2:已完成
    this.missionUrl = data.missionUrl
    this.text = btnTypeMap[data.missionState].text
    this.btnName = btnTypeMap[data.missionState].btnName
    // 特殊处理 实名认证
    if(data.missionType === 0 && !this.missionState) {
      this.text = '请下载APP认证'
    }
  }
}
