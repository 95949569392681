<template>
  <transition name="bounceInUp" @after-leave="afterLeave" @after-enter="afterEnter">
    <div class="notification" :style="{bottom: `${verticalOffset}px`}" v-show="visible" @click.stop.prevent="handleClose">
      <span class="content">{{content}}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'notification',
  props: {
    content: {
      type: String,
      default: ''
    },
    autoClose: {
      type: Number,
      default: 3000
    }
  },
  data () {
    return {
      visible: false,
      verticalOffset: 0,
      height: 0,
      timer: null
    }
  },
  beforeDestroy () {
    this.clearTimer()
  },
  mounted () {
    this.createTimer()
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    afterLeave () {
      this.$emit('closed')
    },
    createTimer () {
      if (this.autoClose) {
        this.timer = setTimeout(() => {
          this.visible = false
        }, this.autoClose)
      }
    },
    clearTimer () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    afterEnter () {
      this.height = this.$el.offsetHeight
    }
  }
}
</script>

<style scoped lang="less">
.notification {
  position: fixed;
  left: 50%;
  z-index: 9000;
  padding: 8px 12px;
  max-width: 60%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  transform: translateX(-50%);
  transition: all .3s;
  border-radius: 4px;
  font-size: 12px;
  word-wrap: break-word;
  word-break: break-all;
}
.content {
  padding: 0;
}
</style>
