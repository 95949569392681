import './Math/range'
import './Math/division'
import './Math/isNumber'
import './Math/animation'
import './HTMLElement/currentStyle'
import './isNull'
import './isArray'
import RepeatRequest from './RepeatRequest'

window.RepeatRequest = RepeatRequest

window.isMobile = window.innerWidth < 1200
