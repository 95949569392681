export default {
	// 记录用户信息
	SAVE_USERINFO: (state, userInfo) => {
		state.userInfo = userInfo;
    },
    // 删除用户信息
	DELETE_USERINFO: (state) => {
		state.userInfo = {};
		removeCookie('cookieUserInfoV1');
	},
	toChangeTab: (state) => {
		state.changeTab++
	},
	SAVE_WEBSOCKET_MESSAGE: (state, states) => {
		state.websocketMessage = states
	},
  // 删除用户信息
  SAVE_SIGNOUT (state) {
    setState(state, 'userInfo', {})
  },
  // 记录时差
  SAVE_JETLAG (state = {}, jetlag) {
    setState(state, 'jetlag', jetlag)
  },
  // 保存资源加载状态,
  SAVE_RESOURCES_STATE (state = {}, states) {
    // 只能写入vuex，不能写入本地缓存
    state.resourcesState = states
  },
  SET_AILINKID (state, id) {
    state.aiLinkId = id
  },
  SET_NEWUSER_SIGN (state, params) {
    if (params.setStore && params.sign) {
      window.localStorage.setItem('ALLTUU_NEWUSER_SIGN', Date.now())
    }
    state.newUserSign = params.sign
  },
  SET_CHECKED_NEWUSER (state, params = true) {
    state.isCheckedNewUser = params
  },
  // 记录gs域名灰度信息
	SAVE_GS_GRAYINFO: (state, gsGrayInfo) => {
		state.gsGrayInfo = gsGrayInfo;
  },
  // 删除gs域名灰度信息
	DELETE_GS_GRAYINFO: (state) => {
		state.gsGrayInfo = null
	}
}



// 写入状态
function setState (state, name, content) {
  state[name] = content
  setStore(name, content)
}

/**
 * 超出数量删除旧数据
 * 数组删除第一个元素
 * 对象根据overflow_index判断，删除小的一个数据
 * @param data Object | Array 要处理的数据集合
 * @param totol 要保留的数量
 */
function removeOverflowData (data, totol) {
  // 如果是数组
  if (window.isArray(data)) {
    data.length > totol && data.shift()
    return data
  }

  // 如果是对象
  let _key = null
  let index = Infinity
  let count = 0
  for (const key in data) {
    data[key].overflow_index = data[key].overflow_index || Object.keys(data).length - 1
    if (data[key].overflow_index < index) {
      _key = key
      index = data[key].overflow_index
    }
    count++
  }
  if (count > totol) {
    delete data[_key]
  }
  if (count > totol) {
    data = removeOverflowData(data, totol)
  }
  return data
}
