/**
 * 请求对象
 * @param {object}
 */
export default class RepeatRequest {
  constructor () {
    this.repeat = false
    this.hasData = true
  }
}
