import { changeNewBieStateModify, queryUserNewBieMission, queryStorageDetail, calculateCloudStorageById, pollCheckCalculateCloudStorage, newGetInfoForToken, getGsGrayJson, queryUserGray } from '@/service/newGetData'
import { Task } from "@/components/noviceGift/object/Task"
import Vue from "vue"
import { aiTrackUserUsage } from '@/module/aiTrack'

let timer = null
async function checkStorage(state) {
  const res = await pollCheckCalculateCloudStorage()
  if(res.d) {
    // 结束轮训
    clearInterval(timer)
    state.storageInfo = res.d.latestStorageDetail
    state.storageInfo.csOverdraftBalance = res.d.csOverdraftBalance
  }
}
export default {
  async CHANGE_NOVICETASKLIST ({ state }, {type, typeState}) {
    if(!state.noviceTaskList?.length) return
    const item = state.noviceTaskList.find(item => item.missionType === type)
    if(!item || (item.missionState >= typeState)) return
    const res = await changeNewBieStateModify(type, typeState)
    if (res.e === 0) {
      const btnTypeMap = {
        0: {
          btnName: 'incomplete',
          text: '去完成'
        },
        1: {
          btnName: 'completed',
          text: '领取奖励'
        },
        2: {
          btnName: 'received',
          text: '已领取'
        }
      }
      if (typeState === 2 ) {
        aiTrackUserUsage('领取新手任务奖励')
      }
      Vue.set(item, 'missionState', typeState)
      Vue.set(item, 'text', btnTypeMap[typeState].text)
      Vue.set(item, 'btnName', btnTypeMap[typeState].btnName)
    }
    return res
  },
  async SET_NOVICETASKLIST({ state }) {
    const taskList = []
    const res = await queryUserNewBieMission(1)
    if(res.e === 0) {
      res.d.list.forEach(item=>{
        taskList.push(new Task(item))
      })
      state.noviceTaskList = taskList
    }
    return res
  },
  async SET_STORAGEINFO({ state }) {
    try {
      const res = await queryStorageDetail()
      if(res.e === 0) {
        state.storageInfo = res.d.latestStorageDetail
        if (state.storageInfo) {
          if (state.storageInfo.userState === null) {
            state.storageInfo.userState = 1
          }
          state.storageInfo.csOverdraftBalance = res.d.csOverdraftBalance
          state.storageInfo.isNewUser = res.d.isNewUser
          state.storageInfo.newUserType = res.d.newUserType // 0为非新人体验期状态 1为新人体验期中 2为体验空间已满/已过
          state.storageInfo.isStorageRedundant = res.d.isStorageRedundant
          state.storageInfo.endTime = res.d.endTime
        }
      }
      return res
    } catch (error) {
      console.log(error)
    }
  },
  // 手动更新云空间
  async MANUAL_UPDATE_STORAGE({ state }) {
    // 先去请求手动更新
    const res = await calculateCloudStorageById()
    if(res.e === 0) {
      window.tipBox.success('更新成功')
      // 轮训
      timer = setInterval(() => {
        checkStorage(state)
      }, 3000);
      return true
    }
    if(res.e === 2003080) {
      window.tipBox.warning('温馨提醒，每小时仅支持手动刷新1次哦～')
    }
    return false
  },
  // async SET_IS_NETDISK({ state }) {
  //   const res = await queryUserGray(10)
  //   if(res.e === 0) {
  //     state.isNetDiskDeliver = Boolean(res.d)
  //   }
  //   return res
  // },
  // 记录用户信息
  async SAVE_USERINFO ({ commit }, token) {
    const res = await newGetInfoForToken(token)
    if (res.e === 0) {
      let obj = res.d.pcObj
      obj.pgDsc = ''
      obj.diamondType = res.d.obj.diamondType
      obj.goldType = res.d.obj.goldType
      if(res.d.obj.diamondType || res.d.obj.goldType){
        obj.isVip = 1
      }else{
        obj.isVip = 0
      }
      setCookie("cookieUserInfoV1",obj);
    }
  },
  // 获取灰度信息
  async UPDATE_GS_GRAYINFO ({ state, commit }) {
    try {
      const res = await getGsGrayJson()
      if (res.stableCore) {
        let isGrayGs = false
        let userId = state.userInfo?.userId
        if (!userId) {
          const uidByCookie = getCookie('cookieUserInfoV1')
          if (uidByCookie) {
            userId = JSON.parse(uidByCookie)?.userId
          }
        }
        if (userId) {
          isGrayGs = ((userId % 100) < res.stableCore.grayPercent) || (res.stableCore.grayUserIds && res.stableCore.grayUserIds.includes(userId))
        }
        commit('SAVE_GS_GRAYINFO', Object.assign({}, res.stableCore, {isGrayGs}))
      }
    } catch (err) {
      console.log(err)
      return null
    }
  }
}
