/*
 * 取范围值
 * 用法：Math.range(min,target,max);
 */
Math.range = (min, target, max) => {
  if (min > max) {
    min = min + max
    max = min - max
    min = min - max
  }
  return Math.min(Math.max(min, target), max)
}
