<template>
  <transition name="fade" @after-leave="$emit('closed')" >
    <div class="message" v-if="visible" :type="type" @click.stop.prevent="$emit('click')">
      <span class="message-icon" :animation="type === 'error'" ><i class="iconfont" :class="iconType" v-if="type"></i></span>{{content}}
    </div>
  </transition>
</template>
<script>
export default {
  name: 'message',
  props: {
    content: {
      type: String,
      default: ''
    },
    autoClose: {
      type: Number,
      default: 1000
    },
    // null | success | error
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    this.createTimer()
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    afterLeave () {
      this.$emit('closed')
    },
    createTimer () {
      if (this.autoClose) {
        this.timer = setTimeout(() => {
          this.visible = false
        }, this.autoClose)
      }
    }
  },
  computed: {
    iconType () {
      switch (this.type) {
        case 'success':
          return 'icon-success'
        case 'error':
          return 'icon-loading'
        default:
          return ''
      }
    }
  }
}
</script>
<style scoped lang="less">
.message {
  position: absolute;
  z-index: 13;
  top: 44px;
  left: 0px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  color: #f7f7f7;
  font-size: 16px;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);

  &-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;

    &[animation] {
      animation: rotateIcon 1s infinite linear;
    }
  }

  &[type=success] {
    background: #29CC56;
  }
  &[type=error] {
    background: #333;
    color: #FF0036;

    & > span {
      color: #999 !important;
    }
  }

  @keyframes rotateIcon {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
