<template>
  <div class="newWorkbench-skeleton" >
    <skeletonLayout :columns="1" rowHeight="60px" ></skeletonLayout>
    <div class="newWorkbench-skeleton-layout">
        <div class="newWorkbench-skeleton-layout-left">
            <skeletonLayout :rows="17" rowHeight="36px" :columns="1" :group="1" :groupGap="10" :rowGap="3"></skeletonLayout>
        </div>
        <div class="newWorkbench-skeleton-layout-right">
            <skeletonLayout :rows="16" :columns="[ 2 ]" :group="4" :groupGap="50" :rowRandowm="true"  :rowWidth="[500, '100%']" :rowGap="5"></skeletonLayout>
        </div>
    </div>
  </div>
</template>
<script>
import { skeletonLayout } from '@alltuu/skeleton'
export default {
  components: {
    skeletonLayout
  }
}
</script>
<style scoped lang="less">
.newWorkbench-skeleton {
  width: 100vw;
  height: 100vh;
  background: #fff;
  box-sizing: border-box;

  &-layout {
      display: flex;
      &-left {
          flex: 0 0 192px;
      
      }
      &-right {
          box-sizing: border-box;
          flex: 1;
          padding: 10px 0 0 10px;
      }
  }
}
</style>