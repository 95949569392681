/**
 * 存localStorage
 */
export const setStore = (name, content) => {
  try {
    if (!name) return
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
    return content
  } catch (e) {
    return content
  }
}

/**
 * 获取localStorage
 */
export const getStore = name => {
  try {
    if (!name) return
    return window.localStorage.getItem(name)
  } catch (e) {
    return ''
  }
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
  try {
    if (!name) return
    window.localStorage.removeItem(name)
  } catch (e) {}
}



export const setSessionStore = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.sessionStorage.setItem(name, content);
}

/**
 * 获取sessionStorage
 */
export const getSessionStore = name => {
	if (!name) return;
	return window.sessionStorage.getItem(name);
}

/**
 * 删除sessionStorage
 */
export const removeSessionStore = name => {
	if (!name) return;
	window.sessionStorage.removeItem(name);
}
