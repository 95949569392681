import { version } from '../../package'
import notify from './notify'
import api from './api'
import loading from './loading'
import isIphoneX from './isIphoneX'
import { Track } from './logTracking'
import clipper from '@alltuu/clipper'

export default (Vue) => {
  Vue.use(notify)
  Vue.use(api)
  Vue.use(loading)
  Vue.use(isIphoneX)

  // /* ============灰度组件============= */
  // /* ============裁剪组件============= */
  Vue.use(clipper)

  /* ============日志追踪（数据采集）============= */
  Vue.use(new Track())
}