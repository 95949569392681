/*
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-06-10 11:40:09
 * @LastEditors: Do not edit
 * @LastEditTime: 2020-08-19 20:15:14
 */
import statistics from '../module/statistics'
import statisticsV2 from '../module/statisticsV2'
import { enterTime, leavePage } from '../module/statistics/pageStayTime'

export default (Vue) => {
  // 发送统计包
  Vue.prototype.$statistics = statistics
  window.$statistics = statistics

  Vue.prototype.$statisticsV2 = statisticsV2
  window.$statisticsV2 = statistics

  // 记录进入页面时间点
  Vue.prototype.$statisticsEnterTime = enterTime
  window.$statisticsEnterTime = enterTime

  // 离开页面计算时间差
  Vue.prototype.$statisticsLeavePage = leavePage
  window.$statisticsLeavePage = leavePage
}
