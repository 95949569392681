<template>
  <div class="workbenchAlert" id="workbenchAlert" @click.stop="clickHandle">
      {{msg}}
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: '',
      clickCallBack: undefined
    }
  },
  methods: {
    clickHandle($event) {
      this.clickCallBack && this.clickCallBack($event)
    }
  }
}
</script>

<style lang="less">
.workbenchAlert {
    position: fixed;
    top: 150px;
    right: ~"calc(50% - 280px)";
    font-size: 14px;
    width: 180px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #ffa319;
    border-radius: 4px;
    color: #f7f7f7;
    z-index: 10;
    animation: appear .5s;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: #ee9208
    }
}
@keyframes appear {
  from {
    transform: translateY(-15px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>