import store from '../store/store'
import getters from '../store/getter'
import { getTime } from './timing'
import { v3Signature, V3CCDNSignature, V4CCDNSignature, OSSSignature } from './encrypt'
// import { V3CDomainName, V4CDomainName, V4ODomainName, V4OBacketMap } from '../../config'

// 选择签名类型
const signature = (options, version = 'v1', url) => {
  if (version === 'v3' || version === 'v4') {
    return v3(options)
  }
  if (version === 'fv2') {
    return fv2(url, options)
  }
  // if (version === 'v3c') {
  //   return v3c(url, options)
  // }
  // if (version === 'v4c') {
  //   return v4c(url, options)
  // }
  // if (version === 'v4o') {
  //   return v4o(url, options)
  // }
}

// 获取token
const getToken = () => {
  return (getters.getUserInfo(store.state).token) || 'null'
}

// rest/v3接口签名算法
const v3 = options => {
  const param = {
    from: 100101,
    version: '0',
    token: getToken(),
    timestamp: getTime()
  }

  Object.assign(param, options)
  const signatureMd5 = v3Signature(param)
  return {
    signature: `v${param.from}-${param.timestamp}-${param.token}-${param.version}-${signatureMd5}`,
    obj: param
  }
}

const fv2 = (url, options) => {
  // 需要使用version为4的接口
  const version4List = ['/fr/search']
  const param = {
    from: 1,
    version: '2',
    token: getToken(),
    timestamp: getTime()
  }
  // 查找是否需要改为V4
  if (version4List.findIndex(ele => url.indexOf(ele) > -1) > -1) {
    param.version = '4'
  }

  Object.assign(param, options)
  const signatureMd5 = v3Signature(param)
  return {
    signature: `v${param.from}-${param.timestamp}-${param.token}-${param.version}-${signatureMd5}`,
    obj: param
  }
}

// // v3c:V3版本CDN鉴权签名算法:
// const v3c = (url, options) => {
//   return V3CCDNSignature(url.replace(V3CDomainName, ''), options)
// }

// // v4c:V4版本CDN鉴权签名算法:
// const v4c = (url, options) => {
//   return V4CCDNSignature(url.replace(V4CDomainName, ''), options)
// }

// // v4o:V4版本OSS鉴权签名算法:
// const v4o = (url, options) => {
//   const { fileName, param } = OSSSignature(url.replace(V4ODomainName, V4OBacketMap[V4ODomainName]), options)
//   return `${url}${fileName}${param}`
// }

// 请求路径拼接
export default (url, options = {}, sign) => {
  let signatureType = 'v1'
  if (url.indexOf('rest/v3/') >= 0 || url.indexOf('https://biu.') >= 0) {
    signatureType = 'v3'
  }
  if (url.indexOf('/rest/v3c/') >= 0) {
    signatureType = 'v3c'
  }
  if (url.indexOf('ai.') >= 0) {
    signatureType = 'fv2'
  }
  if (url.indexOf('/rest/v4/') >= 0) {
    signatureType = 'v4'
  }
  if (url.indexOf('/rest/v4c/') >= 0) {
    signatureType = 'v4c'
  }
  if (url.indexOf('/rest/v4o/') >= 0) {
    signatureType = 'v4o'
  }

  sign = sign || options
  const _sign = signature(sign, signatureType, url)
  if (!_sign) {
    return url
  }
  if (signatureType === 'v4c' || signatureType === 'v4o' || signatureType === 'v3c') {
    return _sign
  }
  for (const i in options) {
    url += '/' + i + options[i]
  }
  return url + '/' + _sign.signature
}
