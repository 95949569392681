(function(doc, win) {
    let docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in win ? 'orientationchange' : 'resize',
        clientWidth = docEl.clientWidth,
        clientHeight = docEl.clientHeight,
        //计算rem
        recalc = function() {
            if (!clientWidth) return;
            if(clientWidth>=1200){
                return 100
            }else{
                return clientWidth / 12;
            }
        },
        //判断横竖屏
        direction = function(){
            return clientWidth > clientHeight ? 'screen-horizontal' : 'screen-vertical';
        },
        //判断屏幕大小
        size = function(){
            if(clientWidth <= 1200){
                return 'screen-size-normal'
            } else if(clientWidth <= 2048) {
                return 'screen-size-large'
            } else {
                return 'screen-size-bigger'
            }
        },
        browser = function(){
            let u = navigator.userAgent;
            let browserArr = [];

            if(u.indexOf('Trident') > -1) browserArr.push('trident');//IE内核
            if(u.indexOf('Presto') > -1) browserArr.push('presto');//opera内核
            if(u.indexOf('AppleWebKit') > -1) browserArr.push('webKit');//苹果、谷歌内核
            if(u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1) browserArr.push('gecko');//火狐内核
            if(!u.match(/AppleWebKit.*Mobile.*/)) browserArr.push('desktop');//是否为桌面浏览器
            if(!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) browserArr.push('ios');//ios终端
            if(u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) browserArr.push('android');//android终端或者uc浏览器
            if(u.indexOf('iPhone') > -1) browserArr.push('iPhone');//是否为iPhone或者QQHD浏览器
            if(u.indexOf('iPad') > -1) browserArr.push('iPad');//是否iPad
            if(u.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') browserArr.push('wechar');//是否是微信浏览器
            if(u.indexOf('Weibo') > -1) browserArr.push('weibo');//是否是微博浏览器

            return browserArr.join(' ');
        },
        //屏幕改变
        screenChange = function(){
            clientWidth = docEl.clientWidth,
            clientHeight = docEl.clientHeight;
            docEl.style.fontSize = recalc() + 'px';
            docEl.className = direction() + ' ' + size() + ' ' + browser();
        };

    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, screenChange, false);
    doc.addEventListener('DOMContentLoaded', screenChange, false);
})(document, window);