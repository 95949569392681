import Vue from 'vue'
import notification from './notification'

const NotificationConstructor = Vue.extend(notification)

let instances = []
let seed = 1

const notify = (options) => {
  let content = ''
  let autoClose = ''
  if (typeof options === 'string') {
    content = options
  } else {
    content = options.content
    autoClose = options.autoClose
  }

  const instance = new NotificationConstructor()
  instance.content = content
  instance.autoClose = autoClose || 3000

  const id = `notification_${seed++}`
  instance.id = id

  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true

  let verticalOffset = 40
  instance.verticalOffset = verticalOffset

  instances.forEach(item => {
    item.verticalOffset += verticalOffset
  })

  instance.vm.$on('closed', () => {
    removeInstance(instance)
    document.body.removeChild(instance.vm.$el)
    instance.vm.$destroy()
  })
  instance.$on('close', () => {
    instance.vm.visible = false
  })
  instances.push(instance)
  return instance.vm
}

const removeInstance = (instance) => {
  if (!instance) return
  const index = instances.findIndex(item => item.id === instance.id)
  instances.splice(index, 1)
}

export default notify
