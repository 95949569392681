import { trackVue } from '@alltuu/track'

export class Track {
  install (Vue) {
    Vue.use(trackVue, {
      env: process.env.VUE_APP_ENV,
      globalData: {
        _from_: 100101
      },
      config: {
        host: 'cn-hangzhou.log.aliyuncs.com',
        project: process.env.VUE_APP_ENV === 'production' ? 'alltuu-frontend-log-tracking' : 'alltuu-frontend-log-tracking-guituu',
        logstore: process.env.VUE_APP_LOG_TRACKING_ENCRYPTION_STORE,
      },
      encryption: true
    })
  }
}
