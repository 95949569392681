<!--
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-08-17 12:13:47
 * @LastEditors: Do not edit
 * @LastEditTime: 2020-08-21 10:32:57
-->
<template>
  <transition name="fade">
    <section class="loading tool-layout">
      <div class="loading-content">
        <img v-if="!icon" class="loading-content-icon" src="./loading.svg" :rotate="rotate"/>
        <i v-else class="loading-content-icon iconfont" :class="`icon-${icon}`"></i>
        <div class="loading-content-content">
          <slot></slot>
        </div>
      </div>
    </section>
  </transition>
</template>
<script>
export default {
  name: 'loadingcomponent',
  props: {
    icon: {
      type: String,
      default: ''
    },
    rotate: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.loading-content {
  width: 138px;
  height: 138px;
  background: rgba(0,0,0,0.75);
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  color: #FFF;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  font-size: 0px;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  &-icon {
    display: inline-block;
    font-size: 38px;
    height: 38px;
  }
  & [rotate="true"] {
    animation: rotate steps(12, end) 1.2s  infinite;
  }
  &-content{
    margin-top: 18px;
    font-size: 13px;
    line-height: 18px;
  }
}
</style>
