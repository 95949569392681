/**
 * url地址对象
 * @param {object}  data   包含广告的数据
 */

export default class Location {
  constructor (url = location.href, base = location.origin) {
    const localUrl = new URL(url, base)
    setQuery(localUrl)

    // 获取参数
    localUrl.get = (name) => {
      return localUrl.searchParams.get(name)
    }

    // 设置参数
    localUrl.set = (name, value) => {
      localUrl.searchParams.set(name, value)
      setQuery(localUrl)
      return localUrl
    }

    // 删除参数
    localUrl.delete = (name) => {
      localUrl.searchParams.delete(name)
      setQuery(localUrl)
      return localUrl
    }
    return localUrl
  }
}

function setQuery (url) {
  url.query = {}
  for (var pair of url.searchParams) {
    url.query[pair[0]] = pair[1]
  }
}
