function checkIphoneX () {
  // iPhone X、iPhone XS
  const isIPhoneX = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 375 && window.screen.height === 812
  // iPhone XS Max
  const isIPhoneXSMax = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 3 && window.screen.width === 414 && window.screen.height === 896
  // iPhone XR
  const isIPhoneXR = /iphone/gi.test(window.navigator.userAgent) && window.devicePixelRatio && window.devicePixelRatio === 2 && window.screen.width === 414 && window.screen.height === 896

  return isIPhoneX || isIPhoneXSMax || isIPhoneXR
}


export default (Vue) => {
  window.$isIphoneX = Vue.prototype.$isIphoneX = checkIphoneX()
}
