<template>
  <div class="QRTip">
    <div class="QRTip-container">
      <slot>
        <div v-if="typeName==='AppDownload'">
          <img src="../images/appQR.png" style="width:366px;height:392px">
        </div>
      </slot>
      <div class="QRTip-close" @click="$emit('close')">
        <i class="iconfont icon-close"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    typeName: {
      type: String,
      default: () => {
          return '';
      }
    }
  }
}
</script>
<style lang="less">
.QRTip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-close{
    // margin: 40px auto 0px ;
    // width: 47px;
    // height: 47px;
    // cursor: pointer;
    position: absolute;
    top: -15px;
    right: -50px;
    width: 34px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    i{
      // text-align: center;
      line-height: 34px;
      font-size: 14px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
