/*
 * @Version: 1.0.0
 * @Author: tenghzilin<tengzhilin@alltuu.com>
 * @Date: 2020-06-24 13:24:58
 * @LastEditors: Do not edit
 * @LastEditTime: 2020-08-19 18:20:35
 */
/**
 * 统计功能
 * 
 * @param   {String}  packName  统计名称，必须为中文
 * @param   {Object}  data      统计参数
 *    @param  {Number}  stayTime      页面停留时间
 *    @param  {String}  url           当前页面路径
 *    @param  {String}  source        来源
 * @param   {String}  async     是否异步
 */
import qs from 'qs'
import { statisticsApi } from '../../config/env'
import signature from '../signature'

export default (packName, data = {}, async = true) => {
  if (!packName) throw new Error('统计名称必须填写')
  if (!/^[\u4e00-\u9fa5]+$/.test(packName)) throw new Error('统计名称必须为中文')

  const _default = {
    stayTime: 0,
    url: location.href,
    source: 1
  }
  let statisticsData = Object.assign(_default, data, { pageName: packName })

  postData(statisticsData, async)
}

// 发送数据
async function postData (data, async) {
  ajax('post', data, !data.stayTime || async)
}

// ajax
function ajax (method = 'post', data, async = true) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    // 请求成功
    xhr.onreadystatechange = function () {
      let res = xhr.responseText ? JSON.parse(xhr.responseText) : {}
      resolve(res)
    }
  
    // 请求出错
    xhr.onerror = function (e) {
      reject(e)
    }
    xhr.open(method.toUpperCase(), signature(`${statisticsApi}/rest/v3/newRoshan`, {}, data), async)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send(qs.stringify(data))
  })
}