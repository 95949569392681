import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './action'
import getters from './getter'

Vue.use(Vuex);

const state = {
	userInfo: {},		//用户信息
	changeTab:0,
	websocketMessage: {},
	resourcesState: {}, // 资源加载状态
  aiLinkId: 0,
  newUserSign: false, // 是否是新用户 24小时内第一次创建账号
  isCheckedNewUser: false,
  noviceTaskList: [],
  storageInfo: {}, // 存储信息
  isNetDiskDeliver: true, // 是否是百度网盘交付灰度用户 11.25全量
  gsGrayInfo: null // gs域名接口的灰度信息
}

export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations
});
