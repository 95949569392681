<template>
  <div class="adver-masker" @click.self="$emit('click')" @click.stop>
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  }
}
</script>
<style lang="less">
  .adver-masker{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 9999999999;
  }
</style>