/*
      用户对象
*/
export const User = (data) => {
    if(data){
        let userType = '0'   //0:摄影师 1:企业 2:普通用户
        if(data.isPro == '1'&&data.pgType == '0'){
            userType = '0';
        }else if(data.isPro == '1'&&data.pgType == '1'){
            userType = '1';
        }else{
            userType = '2';
        }
        return {
            'pgSub':data.pgSub,  //用户域名
            'PgId':data.pgId,     //摄影师 id
            'nike':data.nick,     //用户昵称
            'nick':data.nick,     //为了兼容派瞬用户昵称
            'grpId':data.grpId,   //判断身份,0：游客,1：用户,2：摄影师,3：客服,4：管理员
            'msgNum':data.msgNum,  //未阅读的信息数量
            'userType':userType, //用户身份,
            'userHeadUrl': data.url, //用户头像url
            'portrait': data.url, //为了兼容派瞬 用户头像url
            'userId': data.userId // 用户id
        };
    }else{
        return null;
    }
}
