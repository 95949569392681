/** 判断值不合法
 * @author  wiki
 * @date    2019-3-4 13:42:23
 * @last    2019-3-4 13:42:23
 * @description 不合法的值包括 null | undefined
 *
 * @param  {Object}  data
 */

window.isNull = value => value === null || value === undefined || value === 'null' || value === 'undefined'
