/**
 * 统计页面停留时间功能
 */

/**
 * 记录进入页面时间点
 * 
 * @param   {String}  pathname  页面名：一般为location.pathname
 * @param   {Object}  data      统计参数
 * @param   {String}  async     是否异步
 */
import statistics from './index'
window.statisticsPageStayTime =  {
  pathname: '',
  enterTime: 0
}
export const enterTime = (pathname = location.pathname) => {
  window.statisticsPageStayTime.pathname = pathname.split('?')[0].split('#')[0]
  window.statisticsPageStayTime.enterTime = +new Date()
}

// 离开页面计算时间差
export const leavePage = (packName) => {
  if (!window.statisticsPageStayTime.pathname, !packName) return
  if (!/^[\u4e00-\u9fa5]+$/.test(packName)) throw new Error('统计名称必须为中文')
  statistics(packName, { stayTime: +new Date() - window.statisticsPageStayTime.enterTime }, false)
}